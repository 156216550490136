<template>
  <div class="bill">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">充值明细</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="billItem" v-for="item in list" :key="item.id">
          <div class="billTitle">{{ formatBillName(item) }}</div>
          <div class="billNo">账单编号: {{ item.id }}</div>
          <div class="billAmount">支付金额: {{ item.payMoney / 100 }}元</div>
          <div class="billSta" :style="handleColor(item.status)">
            状态: {{ formatStatus(item.status) }}
          </div>
          <div class="billDate">{{ item.createdAt | orderDate }}</div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryBill } from "@/api/mine";
import PullRefresh from "@/components/PullRefresh";
import { queryVipList } from "@/api/mine";
import { Toast } from "vant";
export default {
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 20,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      vipList: [],
    };
  },
  created() {
    this.getList();
    this.queryVipList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    formatBillName(item) {
      var str = "";
      if (item.vipID == "000000000000000000000000") {
        str = `${parseInt((item.money || 0) / 10)}金币`;
      } else {
        this.vipList.forEach((i) => {
          if (i.productID == item.vipID) {
            str = i.productName;
          }
        });
      }
      return str;
    },
    // 查询会员卡列表
    async queryVipList() {
      // this.$store.commit('app/SET_LOADING', true);
      // try {
      let res = await this.$Api(queryVipList);
      // this.$store.commit('app/SET_LOADING', false);
      if (res && res.code == 200) {
        // this.cardList = []
        res.data.list.forEach((i) => {
          if (
            i.position === "会员卡" ||
            i.position === "商家卡" ||
            i.position == "博主卡"
          ) {
            // // let arr = [...(i.list)];
            // this.cardList = this.cardList.concat(i.list)
            // let cid = this.$route.query.cid;
            // if (cid) {
            //     this.cardList.forEach((item, index) => {
            //         if (item.productID == cid) {
            //             let newArr = this.cardList.splice(index);
            //              this.vipList = [...newArr, ...this.cardList];
            //             // this.handleBgImg([...newArr, ...arr])
            //         }
            //     })
            // } else {
            //     // this.handleBgImg(arr)
            //     this.vipList = [...this.cardList];
            // }
            // this.vipList = [i.list];
            this.vipList = this.vipList.concat(i.list);
            // console.log(this.vipList)
          }
        });
      }
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryBill, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    handleColor(status) {
        let colorStr = "";
        if (status == 2 || status == 4) {
            colorStr = 'color: #FD0563';
        } else if (status == 3) {
            colorStr = 'color: #05B3FD';
        }
        return colorStr;
    },
    //1进行中 2付款失败 3付款成功  4已经退款
    formatStatus(status) {
      let staStr = "";
      switch (status) {
        case 1:
          staStr = "进行中";
          break;
        case 2:
          staStr = "付款失败";
          break;
        case 3:
          staStr = "付款成功";
          break;
        case 4:
          staStr = "已经退款";
          break;
        default:
          break;
      }
      return staStr;
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  height: 100%;
  color: #000;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }
  .main {
    height: calc(100% - 44px);
    padding: 12px 12px 0;
    box-sizing: border-box;
    .billItem {
      // border-bottom: 1px solid #e6e6e6;
      background: #fff;
      padding: 18px 12px;
      box-sizing: border-box;
      margin-bottom: 12px;
      border-radius: 12px;
    }
    .billTitle {
      font-size: 14px;
      margin: 0 0 6px;
    }
    .billNo {
      font-size: 12px;
      color: #666;
      margin-bottom: 6px;
    }
    .billAmount {
      font-size: 12px;
      color: #666;
      margin-bottom: 6px;
    }
    .billSta {
      color: #666;
      margin-bottom: 6px;
      font-size: 14px;
    }
    .billDate {
      font-size: 12px;
      color: #666;
      // margin-bottom: 12px;
    }
  }
}
</style>
